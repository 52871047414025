// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.award-card {
    width: 250px;
    height: 250px;
    border-radius: 15px;
    margin: 20px auto;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Changed this line */
    border: 1px solid rgba(102, 102, 102, 0.732);
    box-shadow: 5px 3px 2px #aaaaaa18;
    transition: transform 0.4s;
}

.award-card:hover {
    transform: scale(1.03);
}

.award-logo {
    width: 50%; 
    height: 100%;
    border-radius: 15%;  
    object-fit: cover;
    order: 2;
    border: 1px solid rgba(102, 102, 102, 0.732);
    box-shadow: 5px 3px 2px #aaaaaa18;
}

.top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50%; 
}

.award-title {
    font-size: 1.2rem;
    order: 1;
    width: 50%; 
}

.award-description {
    font-size: 0.9rem;
}

@media screen and (max-width: 600px) {
    .award-card {
        width: 85vw; 
        height: 85vw;
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/AwardCard.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,2BAA2B,EAAE,sBAAsB;IACnD,4CAA4C;IAC5C,iCAAiC;IACjC,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,QAAQ;IACR,4CAA4C;IAC5C,iCAAiC;AACrC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".award-card {\n    width: 250px;\n    height: 250px;\n    border-radius: 15px;\n    margin: 20px auto;\n    padding: 10px;\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start; /* Changed this line */\n    border: 1px solid rgba(102, 102, 102, 0.732);\n    box-shadow: 5px 3px 2px #aaaaaa18;\n    transition: transform 0.4s;\n}\n\n.award-card:hover {\n    transform: scale(1.03);\n}\n\n.award-logo {\n    width: 50%; \n    height: 100%;\n    border-radius: 15%;  \n    object-fit: cover;\n    order: 2;\n    border: 1px solid rgba(102, 102, 102, 0.732);\n    box-shadow: 5px 3px 2px #aaaaaa18;\n}\n\n.top-section {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    height: 50%; \n}\n\n.award-title {\n    font-size: 1.2rem;\n    order: 1;\n    width: 50%; \n}\n\n.award-description {\n    font-size: 0.9rem;\n}\n\n@media screen and (max-width: 600px) {\n    .award-card {\n        width: 85vw; \n        height: 85vw;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
