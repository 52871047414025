import React from 'react';
import PropTypes from 'prop-types';
import '../css/WorkExperienceCard.css';

const WorkExperienceCard = ({color, logo, jobTitle, jobDescription, jobDates}) => {
    return (
        <div className="work-experience-card" style={{ backgroundColor: color }}>
            <img className="company-logo" src={logo} alt="Company Logo"/>
            <div className="job-info">
                <div className="title-dates">
                    <h3 className="job-title">{jobTitle}</h3>
                    <p className="job-dates">{jobDates}</p>
                </div>
                <p className="job-description">{jobDescription}</p>
            </div>
        </div>
    );
}

WorkExperienceCard.propTypes = {
    color: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    jobTitle: PropTypes.string.isRequired,
    jobDescription: PropTypes.string.isRequired,
    jobDates: PropTypes.string.isRequired,
}

export default WorkExperienceCard;
