import React from 'react';

import styles from '../css/Portfolio.module.css';

import Square from '../components/Square';
import ResearchItem from '../components/ResearchItem';

import { useEffect, useState } from 'react';

// Import more components as necessary

export default function Research() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        const updateWindowWidth = () => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateWindowWidth);

        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        }
        
    },[])


    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <Square size={windowSize / 6.5} left="0%" top="20%" hoverTop="25%" backgroundColor="#FDBEBA" zIndex="2">r</Square>
                    <Square size={windowSize / 5} left="15%" top="10%" hoverTop="15%" backgroundColor="#C0E6FC" zIndex="1">e</Square>
                    <Square size={windowSize / 7.5} left="35%" top="25%" hoverTop="25%" backgroundColor="#FEE5D9" zIndex="3">s</Square>
                    <Square size={windowSize / 8.4} left="47%" top="15%" hoverTop="30%" backgroundColor="#D6FDD5" zIndex="3">e</Square>
                    <Square size={windowSize / 8.4} left="60%" top="10%" hoverTop="15%" backgroundColor="#E8C1F4" zIndex="3">a</Square>
                    <Square size={windowSize / 5} left="70%" top="5%" hoverTop="10%" backgroundColor="#FFBBFC" zIndex="1">r</Square>
                    <Square size={windowSize / 10.5} left="83%" top="30%" hoverTop="30%" backgroundColor="#FFE0B3" zIndex="2">c</Square>
                    <Square size={windowSize / 10.1} left="91%" top="20%" hoverTop="30%" backgroundColor="#E8C1F4" zIndex="2">h</Square>
                </div>
                <div className={styles.projectList}>
                    <ResearchItem 
                        title="Exploring the Role of Social Support when Integrating Generative AI in Small Business Workflows"
                        authors="Quentin Romero Lauro, Jeffrey Bigham, Yasmine Kotturi"
                        venue="ACM Conference on Computer Supported Cooperative Work and Social Computing (CSCW) 2024"
                        abstract=""
                        imageSrc="/cscw_social_support.png"
                        pdfLink="/cscw_2024.pdf"
                        layout="left"
                        bgColor="#E6F3FF"
                        borderColor="#4A90E2"
                        skillList={["Interview Study", "Qualitative Coding", "HCI Research", "Generative AI", "Small Business", "Social Support"]}         
                    />
                    {/* Add more ResearchItem components here as needed */}
                </div>
            </div>
        </>
    );
}
