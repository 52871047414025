import React from 'react';
import PropTypes from 'prop-types';
import '../css/AwardCard.css';

const AwardCard = ({ color, title, logo, description }) => {
    return (
        <div className="award-card" style={{ backgroundColor: color }}>
            <div className="top-section">
                <h3 className="award-title">{title}</h3>
                <img className="award-logo" src={logo} alt="Award Logo"/>
            </div>
            <p className="award-description">{description}</p>
        </div>
    );
}

AwardCard.propTypes = {
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

export default AwardCard;
