// Circle.js

import React from 'react';
import { useState } from 'react';
import styles from '../css/circle.module.css';

const Circle = ({ size, left, top, zIndex, backgroundColor, hoverTop, children }) => {
    const [circleZIndex, setCircleZIndex] = useState(zIndex);

    const circleStyle = {
            maxWidth: 300,
            maxHeight: 300,
            minWidth: 20,
            minHeight: 20,
            width: size,
            height: size,
            borderRadius: '50%',
            position: 'absolute',
            left: left,
            top: top,
            backgroundColor: backgroundColor,
            zIndex: circleZIndex,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // These 3 lines center the content of the circle
            fontSize: '6.9vw', // Adjust this for the font size
            maxFontSize: '4.5em',
            color: '#333', // Text color
            border: `1px solid black`,
            fontWeight: 'bold',
            transition: 'top 0.5s ease-in-out'
    };

    return <div className={styles.circle} style={circleStyle}
                onMouseEnter={() => {
                    setCircleZIndex(zIndex + 10);
                }} 
                onMouseLeave={() => {
                    setCircleZIndex(zIndex);
                }}
            >
                {children}
            </div>;
}

export default Circle;
