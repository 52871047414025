// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Portfolio_header__IZM30 {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    background-color: white;
}

.Portfolio_projectList__CqXPQ {
    background-color: white;
    padding: 8%;
}

.Portfolio_sectionTitle__oE3Kw {
    display: flex;
    padding-bottom: 1%;
}
.Portfolio_container__ae9ez {
    padding-top: 4%;

    align-items: left;
    background-color: white;

}  

@media only screen and (max-width: 600px) {

    .Portfolio_header__IZM30 {
        height: 100px;
        padding-top: 60%;
    }

    .Portfolio_projectList__CqXPQ {
        padding: 4%;
    }
}

@media only screen and (max-width: 400px) {

    .Portfolio_header__IZM30 {

        padding-top: 60%;
        height: 80px;
    }

    .Portfolio_projectList__CqXPQ {
        padding: 2%;
    }
}

@media only screen and (max-width: 300px) {
    .Portfolio_container__ae9ez {
        padding-left: 1%;
        padding-right: 1%;
    }

    .Portfolio_header__IZM30 {
        padding-top: 40%;
    }

    .Portfolio_projectList__CqXPQ {
        padding: 1%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/Portfolio.module.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,kBAAkB;AACtB;AACA;IACI,eAAe;;IAEf,iBAAiB;IACjB,uBAAuB;;AAE3B;;AAEA;;IAEI;QACI,aAAa;QACb,gBAAgB;IACpB;;IAEA;QACI,WAAW;IACf;AACJ;;AAEA;;IAEI;;QAEI,gBAAgB;QAChB,YAAY;IAChB;;IAEA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,gBAAgB;QAChB,iBAAiB;IACrB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":["\n.header {\n    position: relative;\n    width: 100%;\n    height: 400px;\n    overflow: hidden;\n    background-color: white;\n}\n\n.projectList {\n    background-color: white;\n    padding: 8%;\n}\n\n.sectionTitle {\n    display: flex;\n    padding-bottom: 1%;\n}\n.container {\n    padding-top: 4%;\n\n    align-items: left;\n    background-color: white;\n\n}  \n\n@media only screen and (max-width: 600px) {\n\n    .header {\n        height: 100px;\n        padding-top: 60%;\n    }\n\n    .projectList {\n        padding: 4%;\n    }\n}\n\n@media only screen and (max-width: 400px) {\n\n    .header {\n\n        padding-top: 60%;\n        height: 80px;\n    }\n\n    .projectList {\n        padding: 2%;\n    }\n}\n\n@media only screen and (max-width: 300px) {\n    .container {\n        padding-left: 1%;\n        padding-right: 1%;\n    }\n\n    .header {\n        padding-top: 40%;\n    }\n\n    .projectList {\n        padding: 1%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Portfolio_header__IZM30`,
	"projectList": `Portfolio_projectList__CqXPQ`,
	"sectionTitle": `Portfolio_sectionTitle__oE3Kw`,
	"container": `Portfolio_container__ae9ez`
};
export default ___CSS_LOADER_EXPORT___;
