"use client";

import React from 'react';
import { useState } from 'react';
import styles from '../css/Square.module.css';

const Square = ({ size, left, top, hoverTop, backgroundColor, zIndex, children }) => {
    const [squareZIndex, setSquareZIndex] = useState(zIndex);

    const squareStyle = {
        maxWidth: 300,
        minWidth: 20,
        maxHeight: 300,
        minHeight: 20,
        width: size,
        height: size,
        position: 'absolute',
        left: left,
        top: top,
        backgroundColor: backgroundColor,
        zIndex: squareZIndex,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', // These 3 lines center the content of the circle
        fontSize: '6.9vw', // Adjust this for the font size
        color: '#333', // Text color
        border: `1px solid black`,
        fontWeight: 'bold',
        transition: 'top 0.5s ease-in-out'
    };
    return (
        <div className={styles.square} style={squareStyle}
            onMouseEnter={() => {
                setSquareZIndex(zIndex + 10);
            }} 
            onMouseLeave={() => {
                setSquareZIndex(zIndex);
            }}
        
        
        >
            {children}
        </div>
    );
}

export default Square;
