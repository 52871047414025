import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import styles from '../css/ProjectItem.module.css';

const ProjectItem = ({ title, description, imageSrc, layout, bgColor, skillList, borderColor, projectLink }) => {
    const skills = skillList || []; // default to an empty array if skillList is not provided

    // Determine the layout based on the prop
    const isImageLeft = layout === 'left';

    // Style objects for the dynamic colors
    const textStyles = {
        color:'inherit' // default to 'inherit' if textColor is not provided
    };

    const backgroundStyles = {
        backgroundColor: bgColor || 'transparent',
    };

    const borderStyles = {
        border: borderColor ? `15px solid ${borderColor}` : 'none' // if borderColor is provided, use it, otherwise default to 'none'
    };

    return (
        <div className={styles.projectItem}>
            {isImageLeft && (
                <div className={styles.projectImage}>
                    <img src={imageSrc} alt={title} style={borderStyles}/>
                </div>
            )}

            <div className={styles.projectText} style={backgroundStyles}>
                <h2 style={textStyles}>
                    {title}
                    {projectLink && (
                        <a href={projectLink} target="_blank" rel="noopener noreferrer" className={styles.projectLink}>
                            <FaExternalLinkAlt size={16} />
                        </a>
                    )}
                </h2>
                <p style={textStyles}>{description}</p>

                <div className={styles.skillsContainer}>
                    {skills.map(skill => (
                        <span className={styles.skillTag} key={skill}>
                            {skill}
                        </span>
                    ))}
                </div>

            </div>

            {!isImageLeft && (
                <div className={`${styles.projectImage} ${styles.projectImageRight}`}>
                    <img src={imageSrc} alt={title} style={borderStyles} />
                </div>
            )}
        </div>
    );
}

export default ProjectItem;
