import React from 'react';
import styles from '../css/Contact.module.css';
import Square from '../components/Square';
import { useState, useEffect } from 'react';
const twitterLogo =  '/twitter-dead.png';
const linkedInLogo = '/linkedin.png';
const cathyImage = '/cathy-clip-art.png'

export default function Contact() {
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        const updateWindowSize = () => {
          setWindowSize(window.innerWidth);
        };
        
        window.addEventListener('resize', updateWindowSize);

        return () => {
            window.removeEventListener('resize', updateWindowSize);
          }
      }, []);



    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <Square size={windowSize / 6.5} left="0%" top="20%" hoverTop="25%" backgroundColor="#FDBEBA" zIndex="2">c</Square>
                    <Square size={windowSize / 5} left="15%" top="10%" hoverTop="15%" backgroundColor="#C0E6FC" zIndex="1">o</Square>
                    <Square size={windowSize / 7.5} left="35%" top="25%" hoverTop="25%" backgroundColor="#FEE5D9" zIndex="3">n</Square>
                    <Square size={windowSize / 8.4} left="47%" top="15%" hoverTop="30%" backgroundColor="#D6FDD5" zIndex="3">t</Square>
                    <Square size={windowSize / 8.4} left="60%" top="10%" hoverTop="15%" backgroundColor="#E8C1F4" zIndex="3">a</Square>
                    <Square size={windowSize / 5} left="70%" top="5%" hoverTop="10%" backgroundColor="#FFBBFC" zIndex="1">c</Square>
                    <Square size={windowSize / 10.5} left="88%" top="30%" hoverTop="30%" backgroundColor="#FFE0B3" zIndex="2">t</Square>
                </div>
                
                <div className={styles.contactDetails}>
                    <h1 className={styles.linkHeading}>email me at:</h1>
                    <a href="mailto:quentinrl@pitt.edu" className={styles.email}>
                        quentinrl [at] pitt [dot] edu
                    </a>
                    <h1 className={styles.linkHeading}>see updates:</h1>
                    <a href="https://twitter.com/Qromerolauro" target="_blank" rel="noopener noreferrer" className={styles.twitterLink}>
                        <img src={twitterLogo} alt="Twitter Logo" className={styles.twitterLogo}/>
                        <span>@Qromerolauro</span>
                    </a>
                    <h1 className={styles.linkHeading} color="black"><a href="https://www.uclalawreview.org/professionalism-as-a-racial-construct/">overly professional at:</a></h1>
                        <a href="https://www.linkedin.com/in/quentinrl/" target="_blank" rel="noopener noreferrer" className={styles.linkedinLink}>
                            <img src={linkedInLogo} alt="LinkedIn Logo" className={styles.linkedinLogo} width={100}/>
                            <span>Quentin Romero Lauro</span>
                        </a>
                </div>

            </div>
            <a target="_blank" rel="noreferrer" href="http://pitt.edu">
                <img src={cathyImage}
                        alt="Clipart of the Cathedral of Learning, from the University of Pittsburgh, where I go to school!"
                        className={styles.cathyImage}
                />
            </a>
        </>
    );
}
