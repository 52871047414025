import styles from '../css/Introduction.module.css';

export default function Introduction({imageIndex}){
    const imageString  = "/woodstreet" + imageIndex + ".jpeg";

    return (
        <div className={styles.row}>
            <div className={styles.column}>
                <div className={styles.text}>
                    <h2>
                        Hi, my name is Quentin 
                    <img 
                            src="/sketch_of_me.png" 
                            alt="Pitt Panther" 
                            style={{
                                width: '70px',
                                height: '70px',
                                marginLeft: '0px',
                                marginBottom: '20px',
                                verticalAlign: 'middle'
                            }}
                        />
                    </h2>
                    <h2>
                        B.S. in Computer Science @ Pitt
                        <img 
                            src="/pitt_panther.png" 
                            alt="Pitt Panther" 
                            style={{
                                width: '40px',
                                height: '40px',
                                marginLeft: '5px',
                                verticalAlign: 'middle'
                            }}
                        />
                    </h2>
                    <h2>I design and build technologies that help people of <span style={{
                        background: 'linear-gradient(to right, #ff4500, #ffa500)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        display: 'inline-block'
                    }}>all backgrounds</span> harness the power of AI.</h2>
                    <h2>Let's connect.</h2>
                </div>
            </div>

            <div className={styles.column}>
                <div className={styles.imageContainer}>
                    <img src={imageString}
                
                         alt="Quentin Romero Lauro posing in a blocky LED light tunnel"
                         className={styles.image}
                    />
                </div>
            </div>
        </div>
    );
}
