import styles from '../css/PortfolioCard.module.css';

// PortfolioCard.js

export default function PortfolioCard({
  projectName,
  shortDescription,
  imageLink,
  imageAltText,
  borderColor,
  style,
}) {
  // The 'style' prop will be used to pass additional styling if needed.
  return (
    <div className={styles.card} style={{ border: `20px solid ${borderColor}`, ...style }}>
      <div className={styles.row}>
        <div className={styles.column} style={{ maxWidth: '100%' }}> {/* Ensure the column takes full width */}
          <h3>{projectName}</h3>
          <p className={styles.text}>{shortDescription}</p>
        </div>
        <div className={styles.column} style={{ maxWidth: '100%' }}> {/* This div acts as an image container */}
          <img
            src={imageLink}
            alt={imageAltText}
            className={styles.image} // Responsive image sizes are handled by CSS
          />
        </div>
      </div>
    </div>
  );
}

