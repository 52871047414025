import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import styles from '../css/ResearchItem.module.css';

const ResearchItem = ({ title, authors, venue, abstract, imageSrc, pdfLink, layout, bgColor, skillList, borderColor }) => {
    const skills = skillList || [];
    const isImageLeft = layout === 'left';

    const backgroundStyles = {
        backgroundColor: bgColor || 'transparent',
    };

    const borderStyles = {
        border: borderColor ? `15px solid ${borderColor}` : 'none'
    };

    return (
        <div className={styles.researchItem}>
            {isImageLeft && (
                <div className={styles.researchImage}>
                    <img src={imageSrc} alt={title} style={borderStyles}/>
                </div>
            )}

            <div className={styles.researchText} style={backgroundStyles}>
                <h2>
                    {title}
                    <a href={pdfLink} target="_blank" rel="noopener noreferrer" className={styles.titleLink}>
                        <FaExternalLinkAlt size={16} />
                    </a>
                </h2>
                <p className={styles.authors}>{authors}</p>
                <p className={styles.venue}>{venue}</p>
                <p className={styles.abstract}>{abstract}</p>

                <div className={styles.skillsContainer}>
                    {skills.map(skill => (
                        <span className={styles.skillTag} key={skill}>
                            {skill}
                        </span>
                    ))}
                </div>

            </div>

            {!isImageLeft && (
                <div className={`${styles.researchImage} ${styles.researchImageRight}`}>
                    <img src={imageSrc} alt={title} style={borderStyles} />
                </div>
            )}
        </div>
    );
}

export default ResearchItem;
