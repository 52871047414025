// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Introduction_background__Xvri8 {
}

.Introduction_row__labrg  {
    margin-top: 10px;
    display: flex;
    /* TODO: maybe box-sizing can go globally later?a */
    box-sizing: border-box;  
    background-color: white;

}

.Introduction_column__lYTuQ {
    display: flex;
    flex: 50% 1;
    justify-content: center;
    position: relative;
}

.Introduction_text__629PA {
    position: absolute;
    top: 50%;
    padding-left: 15%;
    align-content: center;
    justify-content: center;
    transform: translate(0, -50%);
    text-align: left;
}

.Introduction_text__629PA h2 {
    padding: 10px;
    color: black;
}

.Introduction_imageContainer__EqMC7 {
    display: flex;
    padding-right: 15%;
    padding-top: 10%;
    padding-bottom: 10%;
    justify-content: center;

}

.Introduction_image__32ZmL {
    border-radius: 10%;
    max-width: 100%;
    height: auto;
    width: 60vh;
    border: 1px solid rgba(102, 102, 102, 0.732);
    box-shadow: 5px 5px 3px #aaaaaa18;;
}

@media(max-width: 1000px){
    .Introduction_row__labrg {
        display: grid;
    }
    
    .Introduction_text__629PA{
        text-align: center;
        position: relative;
        padding: 50px;
    }

    .Introduction_imageContainer__EqMC7 {
        padding: 50px;
    } 

}`, "",{"version":3,"sources":["webpack://./src/css/Introduction.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mDAAmD;IACnD,sBAAsB;IACtB,uBAAuB;;AAE3B;;AAEA;IACI,aAAa;IACb,WAAS;IACT,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,iBAAiB;IACjB,qBAAqB;IACrB,uBAAuB;IACvB,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;;AAE3B;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,WAAW;IACX,4CAA4C;IAC5C,iCAAiC;AACrC;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,kBAAkB;QAClB,kBAAkB;QAClB,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;;AAEJ","sourcesContent":[".background {\n}\n\n.row  {\n    margin-top: 10px;\n    display: flex;\n    /* TODO: maybe box-sizing can go globally later?a */\n    box-sizing: border-box;  \n    background-color: white;\n\n}\n\n.column {\n    display: flex;\n    flex: 50%;\n    justify-content: center;\n    position: relative;\n}\n\n.text {\n    position: absolute;\n    top: 50%;\n    padding-left: 15%;\n    align-content: center;\n    justify-content: center;\n    transform: translate(0, -50%);\n    text-align: left;\n}\n\n.text h2 {\n    padding: 10px;\n    color: black;\n}\n\n.imageContainer {\n    display: flex;\n    padding-right: 15%;\n    padding-top: 10%;\n    padding-bottom: 10%;\n    justify-content: center;\n\n}\n\n.image {\n    border-radius: 10%;\n    max-width: 100%;\n    height: auto;\n    width: 60vh;\n    border: 1px solid rgba(102, 102, 102, 0.732);\n    box-shadow: 5px 5px 3px #aaaaaa18;;\n}\n\n@media(max-width: 1000px){\n    .row {\n        display: grid;\n    }\n    \n    .text{\n        text-align: center;\n        position: relative;\n        padding: 50px;\n    }\n\n    .imageContainer {\n        padding: 50px;\n    } \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `Introduction_background__Xvri8`,
	"row": `Introduction_row__labrg`,
	"column": `Introduction_column__lYTuQ`,
	"text": `Introduction_text__629PA`,
	"imageContainer": `Introduction_imageContainer__EqMC7`,
	"image": `Introduction_image__32ZmL`
};
export default ___CSS_LOADER_EXPORT___;
