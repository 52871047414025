// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* circle.module.css */
.circle_circle__GBnp8 {
    position: absolute;
    border-radius: 50%;
    transition: transform .5s;
}

.circle_circle__GBnp8:hover {
    transform: scale(1.05);
    transition: transform .5s;
}`, "",{"version":3,"sources":["webpack://./src/css/circle.module.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;AAC7B","sourcesContent":["/* circle.module.css */\n.circle {\n    position: absolute;\n    border-radius: 50%;\n    transition: transform .5s;\n}\n\n.circle:hover {\n    transform: scale(1.05);\n    transition: transform .5s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": `circle_circle__GBnp8`
};
export default ___CSS_LOADER_EXPORT___;
