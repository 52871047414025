// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Square_square__mxuYd {
    position: absolute;
    transition: transform 0.3s ease-in-out;
}

.Square_square__mxuYd:hover {
    transform: scale(1.05); /* You can adjust the scaling as per your liking */
    transition: transform 0.3s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/css/Square.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,sCAAsC;AAC1C;;AAEA;IACI,sBAAsB,EAAE,kDAAkD;IAC1E,sCAAsC;AAC1C","sourcesContent":[".square {\n    position: absolute;\n    transition: transform 0.3s ease-in-out;\n}\n\n.square:hover {\n    transform: scale(1.05); /* You can adjust the scaling as per your liking */\n    transition: transform 0.3s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"square": `Square_square__mxuYd`
};
export default ___CSS_LOADER_EXPORT___;
