import styles from '../css/Footer.module.css';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <a href="mailto:quentinrl@pitt.edu" className={styles.left}>quentinrl [at] pitt [dot] edu</a>
      <p>This website was made with love and React, by yours truly :)</p>
      <div className={styles.right}>
        © 2024 Quentin Romero Lauro
      </div>
    </footer>
  );
}
