import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaGithubSquare, FaLinkedin, FaTwitterSquare, FaBars } from 'react-icons/fa';
import styles from '../css/NavigationBar.module.css';

export default function NavigationBar() {
    const [showNavBar, setShowNavBar] = useState(false);

    function handleMenuToggle() {
        setShowNavBar(state => !state);
    }

    return (
        <nav className={styles.navbar}>
            <div className={styles.container}>
                <div className={styles.logoContainer}>
                    <div className={styles.logo}>
                        Quentin Romero Lauro
                    </div>
                </div>
                <div className={styles.menuIcon} onClick={handleMenuToggle}>
                    <FaBars />
                </div>

                <div className={`${styles.navElements} ${showNavBar ? styles.active : ''} ${styles.icons}`}>
                    <a href="http://github.com/QuentinRomeroLauro">
                        <FaGithubSquare size="25" color="black" />
                    </a>
                    <a href="http://www.linkedin.com/in/quentinrl">
                        <FaLinkedin size="25" color="black"/>
                    </a>
                    <a href="https://twitter.com/Qromerolauro">
                        <FaTwitterSquare size="25" color="black"/>
                    </a>
                </div>

                {/* top sections to click on */}
                <div className={`${styles.navElements} ${showNavBar ? styles.active : ''}`}>
                    <ul>
                        <li>
                            <Link to="/" className={styles.navLinkStyle}>home</Link>
                        </li>
                        <li>
                            <Link to="/portfolio" className={styles.navLinkStyle}>portfolio</Link>
                        </li>
                        <li>
                            <Link to="/research" className={styles.navLinkStyle}>research</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={styles.navLinkStyle}>contact</Link>
                        </li>
                        <li>
                            <a href="https://medium.com/@Quentinromero" target="_blank" rel="noopener noreferrer" className={styles.navLinkStyle}>blog</a>
                        </li>
                        <li>
                            <Link to="/Research_Interest_Statement_Quentin_Romero_Lauro.pdf" target="_blank" rel="noopener noreferrer" className={styles.navLinkStyle}>research statement</Link>
                        </li>
                        <li>
                            <a href="/Quentin_Romero_Lauro_CV.pdf" target="_blank" rel="noopener noreferrer" className={styles.navLinkStyle}>cv</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
