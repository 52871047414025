import React from 'react';
import AwardCard from './AwardCard';
import '../css/AwardCardContainer.css';

const AwardCardContainer = ({ awards }) => {
    return (
        <div className="award-card-container">
            {awards.map(award => (
                <AwardCard 
                    color={award.color} 
                    title={award.title} 
                    logo={award.logo} 
                    description={award.description} 
                    key={award.title} 
                />
            ))}
        </div>
    );
}

export default AwardCardContainer;
